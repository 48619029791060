@import "./icons.scss";
@import "./controls.scss";

body {
  margin: 0;
  // font-family: "Taviraj", serif;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

.App {
  background-image: url("../images/background.png");
  background-size: cover;
}

.semi-heading {
}

$color-primary: #7a2538;

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-20 {
  margin-bottom: 20px;
}

.mb-32 {
  margin-bottom: 32px;
}

.ml-32 {
  margin-left: 32px;
}
.mb-16 {
  margin-bottom: 16px;
}

.mr-32 {
  margin-right: 32px;
}

.mr-64 {
  margin-right: 64px;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.caption {
  &.email {
    font-size: 13px;
    letter-spacing: 0.08em;
    font-family: Rubik-Regular;
    font-weight: 400;
  }

  &.corporate {
    font-size: 13px;
    letter-spacing: 0.2em;
    font-family: OpenSans-Regular;
    font-weight: 600;
  }
  color: $color-primary;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.fl-1 {
  flex: 1;
}

.page {
  padding: 0 120px;

  &.general-padding {
    padding: 0 210px;

    @media screen and (max-width: 1240px) {
      padding: 0 70px;
    }

    @media screen and (max-width: 639px) {
      padding: 0;
    }
  }
  // max-width: 1110px;
  // margin: auto;
  // height: calc(100vh - 296px);
  @media screen and (max-width: 1240px) {
    padding: 0 70px;
  }

  @media screen and (max-width: 639px) {
    padding: 0;
  }
}

.full-page {
  width: 100%;
}

.icon-top {
  width: 100%;
}

.fWohKN {
  width: 45px;
  @media screen and (max-width: 460px) {
    width: 35px !important;
    margin: 15px !important;
  }
}

@font-face {
  font-family: "Rubik-Regular";
  src: local("Rubik-Regular"),
    url(../fonts/rubik/Rubik-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans-Regular";
  src: local("OpensSans-Regular"),
    url(../fonts/Open_Sans/OpenSans-Regular.ttf) format("truetype");
}
