.pre-heading {
  font-size: 14px;
  font-family: Rubik-Regular;
  font-weight: 700;
  letter-spacing: 1px;
  color: #c56f72;
  margin-bottom: 4px;
  margin-left: 3px;
  line-height: 28px;
}

.container-heading {
  font-size: 40px;
  font-family: OpenSans-Regular;
  font-weight: 700;
  letter-spacing: 1px;
  color: #85001e;
  margin-bottom: 12px;
  line-height: 43px;

  &.trust {
    font-size: 90px;
    line-height: 88px;
    color: #c58291;
    letter-spacing: 5px;
    margin-top: 25px;
    .extra-color {
      color: #85001e;
    }
  }

  &.biography {
    font-size: 80px;
    line-height: 77px;
    letter-spacing: -0.005em;
    text-align: left;
    margin-top: 25px;
    color: #5b3d43;
    @media screen and (max-width: 640px) {
      margin-top: 0px;
    }
    &.biography-topic {
      font-size: 22px;
      line-height: 27px;
      margin-top: 5px;
    }
  }

  &.gallery {
    margin-left: 3px;
    font-size: 28px;
    line-height: 30px;
  }
}

.date-span {
  font-size: 14px;
  font-family: OpenSans-Regular;
  font-weight: 600;
  color: #979da1;
  margin-bottom: 4px;
  line-height: 19px;
  letter-spacing: 2%;
  margin-left: 2px;

  &.location {
    margin-bottom: 20px;
  }
}

.container-body {
  font-size: 14px;
  font-family: OpenSans-Regular;
  font-weight: 400;
  color: #5c6064;
  margin-bottom: 4px;
  line-height: 26px;
  letter-spacing: 2%;
  margin-bottom: 32px;
}

.donate-button {
  width: 250px;
  height: 60px;
  background-color: #efd0d0;
  border-radius: 60px;
  outline: none !important;
  border: none;
  color: #85001e;
  font-size: 16px;
  cursor: pointer;
  font-family: Rubik-Regular;
  font-weight: 700;
  line-height: 25px;
  box-shadow: 0 17px 20px -12px hsla(0, 34.55%, 89.22%, 1);

  &:hover {
    background-color: #efd0d0;
    outline: #85001e;
    border: 2px solid #85001e;
    color: #85001e;
    font-size: 16px;
  }

  &:active {
    background-color: #efd0d0 !important;
    outline: none !important;
    border: 2px solid #85001e !important;
    color: #85001e !important;
    font-size: 16px;
    box-shadow: none !important;
  }

  &:focus {
    background-color: #efd0d0 !important;
    outline: none !important;
    border: 2px solid #85001e !important;
    color: #85001e !important;
    font-size: 16px;
    box-shadow: none !important;
  }
}
