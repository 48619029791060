.react-player {
  height: 506px !important;
  max-height: 560px !important;
  &.books {
    width: 70% !important;
    margin-left: 15% !important;
    @media screen and (max-width: 640px) {
      width: 100% !important;
      margin-left: 0% !important;
    }
  }

  @media screen and (max-width: 968px) {
    height: 450px !important;
  }

  @media screen and (max-width: 500px) {
    height: 350px !important;
  }

  @media screen and (max-width: 400px) {
    height: 250px !important;
  }

  @media screen and (max-width: 300px) {
    height: 220px !important;
  }
}
