.nav-bar {
  display: flex;
  justify-content: space-between;
  padding: 0 120px;
  .logo {
    width: 176px;
  }

  &.no-padding {
    padding: 0px;
  }
  .menu-logo-parent {
    @media screen and (max-width: 1024px) {
      background: transparent;
      display: flex;
      width: 100%;
      flex-direction: row;
    }

    .menu-logo {
      background-image: url("../../images/icons/svg/menu-red.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 18px;
      width: 24px;
      height: 24px;
      margin: 40px 0;
      display: none;
      cursor: pointer;
      margin-left: 94%;

      @media screen and (max-width: 640px) {
        margin-left: 88%;
      }
    }
  }

  .menu-items {
    list-style: none;
    padding: 40px 0;
    display: inline-block;
    margin: 0;
    a {
      text-decoration: none;
      // color: #6d5f62;
      color: #7a2538;
      font-size: 14px;
      letter-spacing: 0.04em;
      font-family: OpenSans-Regular;
      font-weight: 400;
      line-height: 19px;
      &:hover {
        font-weight: 900;
      }
    }
    .nav__menu-item {
      display: inline-block;
      position: relative;
      p {
        font-size: 14px;
        letter-spacing: 0.04em;
        font-family: OpenSans-Regular;
        font-weight: 400;
        color: #7a2538;
        line-height: 19px;
        cursor: pointer;
        &:hover {
          font-weight: 900;
        }
      }
    }
  }

  .nav__submenu {
    list-style: none;
    padding: 0;
    margin: 0;
    text-transform: none;
    display: none;
    position: absolute;
    left: 0;
    border-radius: 12px;
    z-index: 5555;

    .nav__submenu-item {
      display: block;
      padding: 16px;
      padding-right: 52px;
      color: #7a2538;
      border-left: 1px solid #7a2538;
      border-right: 1px solid #7a2538;
      max-width: 100%;
      background-color: #ffffff;
      &.books {
        width: 100%;
      }

      &:hover {
        color: white;
        background-color: #7a2538;
      }
      &:first-child {
        margin-top: 12px;
        border: 1px solid #7a2538;
        border-radius: 12px 12px 0px 0px;
        border-bottom: none;
      }

      &:last-child {
        border-radius: 0px 0px 12px 12px;
        border: 1px solid #7a2538;
        border-top: none;
      }
    }
  }

  .nav__menu-item:hover {
    p {
      font-weight: 900;
    }
    .nav__submenu {
      display: block;
    }
  }
}

@media screen and (max-width: 1240px) {
  .nav-bar {
    padding: 0 70px;
  }
}

@media screen and (max-width: 1080px) {
  .nav-bar {
    .logo {
      width: 130px;
    }
  }
}

@media screen and (max-height: 992px) {
  .nav-bar {
    .logo {
      width: 100px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .nav-bar {
    transition: all linear 200ms;
    &.open {
      // background-color: #4e4e4e;
      .menu-logo-parent {
        .menu-logo {
          background-image: url("../../images/icons/svg/menu-red.png");
        }
      }
    }
    &.closed {
      // background-color: white;
    }
    .logo {
      z-index: 200;
      position: relative;
    }
    .menu-logo-parent {
      .menu-logo {
        display: block;
        z-index: 200;
      }
    }

    .menu-items {
      color: #7a2538;
      background-color: #fff9f9;
      position: absolute;
      display: flex;
      flex-direction: column;
      z-index: 100;
      padding: 0;
      padding-top: 24px;
      right: 0;
      border-bottom: 1px solid #898989;
      width: 100%;
      height: 100%;
      transition: all linear 200ms;
      overflow-y: scroll;
      a {
        margin: 0;
        color: #7a2538;
      }

      .nav__menu-item {
        padding: 16px 70px;
        margin: 0;

        &.collapsed-link {
          a {
            font-weight: 900;
          }
        }
        // border: none;
        p {
          font-size: 14px;
          color: #7a2538;
          font-weight: 900;
        }
      }

      .nav__submenu {
        position: relative;
        display: block;
        color: #7a2538;
        border: none;
        left: 0;
        padding-bottom: 0;
        background-color: #ffffff;
        .nav__submenu-item {
          padding: 12px 20px;
          border-radius: 0px;
          border: none;

          &:first-child {
            margin-top: 12px;
          }
        }
      }

      &.open {
        top: 0;
        padding-top: 96px;
        opacity: 1;
      }
      &.closed {
        top: calc(-100% - 267px);
        opacity: 0;
      }
    }
  }
}

@media screen and (max-width: 639px) {
  .nav-bar {
    padding: 0 24px;
    .logo {
      width: 85px;
      height: 140px;
    }
    .menu-items {
      .nav__menu-item {
        // padding: 16px 24px;
        margin: 0;
      }
      &.open {
        padding-top: 96px;
        // background-color: #4e4e4e;
      }
    }
  }
}

.mobile {
  .nav-bar {
    padding: 0px 24px !important;
    .logo {
      width: 84px;
    }
  }
}

.hideLogo {
  display: none;
}
