.contact-us-page {
  .title-bar {
    margin-top: 32px;
  }

  .content {
    padding-top: 16px;
    p {
      font-size: 1.25rem;
      line-height: 2.125rem;
    }
    margin-bottom: 64px;
  }

  .contact-footer {
    width: 50%;
    justify-content: space-between;
  }

  .contact-footer {
    .social-icon-large:first-child {
      margin-left: -36px;
    }
  }

  @media screen and (max-width: 960px) {
    .contact-footer {
      .social-icon-large:first-child {
        margin-left: -26px;
      }
    }
  }

  @media screen and (max-width: 639px) {
    .title-bar {
      margin-top: 0;
    }
    .content {
      padding: 0 24px;
      p {
        font-size: 1rem;
        line-height: 1.75rem;
      }
      margin-bottom: 24px;
    }

    .contact-footer {
      padding: 0 24px;
      width: calc(100% - 42px);
      .social-icon-large:first-child {
        margin-left: -18px;
      }
    }
  }
}
