.category-select {
  cursor: pointer !important;
  &__control {
    border-radius: 20px !important;
  }
  &__control:hover,
  &__control--is-focused {
    border-color: #7d2639 !important;
    box-shadow: 0 0 0 1px #7d2639 !important;
    color: white !important;
  }
  &__option:active,
  &__option--is-focused {
    background-color: #7d2639 !important;
    color: white !important;
  }

  &__option--is-selected {
    background-color: #f8ecec !important;
    color: #7d2639 !important;
    font-weight: 900;
  }
  &__option--is-selected:hover {
    border-color: #7d2639 !important;
    box-shadow: 0 0 0 1px #7d2639 !important;
    background-color: #7d2639 !important;
    color: white !important;
  }
  // &__option--is-selected{
  //   background-color: #f8ecec !important;
  //   color: #7a2538 !important;
  // }

  &__option {
    border-left: 1px solid #7a2538;
    border-right: 1px solid #7a2538;
    padding: 16px !important;
    cursor: pointer !important;
    &:first-child {
      border: 1px solid #7a2538;
      border-radius: 20px 20px 0px 0px;
      border-bottom: none;
    }

    &:last-child {
      border-radius: 0px 0px 20px 20px;
      border: 1px solid #7a2538;
      border-top: none;
    }
  }

  &__menu {
    background-color: #f8ecec !important;
    color: #7a2538 !important;
    font-family: OpenSans-Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.02em;
    cursor: pointer !important;
    border: 0 !important;
    border-radius: 20px !important;
    margin-top: 16px;
    &.css-26l3qy-menu {
      margin-top: 16px;
    }
    .css-4ljt47-MenuList,
    .css-11unzgr {
      padding: 0px !important;
    }
  }
}
