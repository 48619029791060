.trust-item {
  width: 100%;
  margin-bottom: 120px;
  @media screen and (max-width: 960px) {
    margin-bottom: 0;
  }
  .content {
    display: flex;
    position: relative;

    .details {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 48px;
      h1 {
        margin-bottom: 30px;
        font-size: 3rem;
      }
      p {
        font-size: 1.25rem;
      }
    }
    .photo {
      // min-width: 500px;
      position: relative;
      display: flex;
      flex-direction: column;
      // max-width: 484px;
      min-width: 484px;
      width: 40%;
      z-index: 1;
      img {
        color: white;
        flex: 1;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        background: linear-gradient(0deg, #333333, #333333);
        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }
    }
    .border {
      position: absolute;
      right: -120px;
      margin: 60px 0;
      background-color: #e5e5e5;
      width: 120px;
      height: calc(100% - 120px);
    }

    @media screen and (max-width: 960px) {
      flex-direction: column-reverse;

      .details {
        background: #e5e5e5;
        padding: 24px;
        margin: 0;
        h1 {
          font-size: 1.375rem;
          margin-bottom: 8px;
        }

        p {
          font-size: 1rem;
        }
      }
      .photo {
        // padding: 24px;
        // background: #e5e5e5;
        max-width: unset;
        width: calc(100%);
      }

      .border {
        display: none;
      }
    }

    @media screen and (max-width: 639px) {
      .details {
        background: #e5e5e5;
        padding: 0 24px 24px;
      }
      .photo {
        padding: 24px;
        background: #e5e5e5;
        max-width: unset;
        min-width: unset;
        width: calc(100% - 48px);
      }
    }
  }

  // &.odd {
  //   .content {
  //     .details {
  //       margin-right: 48px;
  //     }
  //     .border {
  //       right: -120px;
  //     }
  //   }
  // }

  &.even {
    .content {
      flex-direction: row-reverse;
      .details {
        margin-right: 0;
        margin-left: 48px;
      }
      .border {
        left: -120px;
      }

      @media screen and (max-width: 960px) {
        flex-direction: column-reverse;

        .details {
          background: #e5e5e5;
          padding: 24px;
          margin: 0;
          h1 {
            font-size: 1.375rem;
            margin-bottom: 8px;
          }

          p {
            font-size: 1rem;
          }
        }
        .photo {
          // padding: 24px;
          // background: #e5e5e5;
          max-width: unset;
          width: calc(100%);
        }

        .border {
          display: none;
        }
      }

      @media screen and (max-width: 639px) {
        .details {
          background: #e5e5e5;
          padding: 0 24px 24px;
        }
        .photo {
          padding: 24px;
          background: #e5e5e5;
          max-width: unset;
          min-width: unset;
          width: calc(100% - 48px);
        }
      }
    }
  }
}

.trust-logo {
  width: 100%;
  text-align: center;
  @media screen and (max-width: 640px) {
    margin-top: 20px;
  }
  > img {
    width: 74px;
    height: 77px;
    @media screen and (max-width: 640px) {
      width: 44px;
      height: 47px;
    }
  }
  font-family: OpenSans-Regular;

  .trust-logo-text {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.005em;
    color: #5b3d43;
  }

  .trust-logo-body-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0.02em;
    color: #c56f72;
  }

  .trust-logo-bottom-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #41474b;
  }
}

.trust-body {
  width: 100%;
  .body-text {
    font-family: OpenSans-Regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: #41474b;
    &.biography {
      color: #5a697f;
      margin-top: 12px;
      @media screen and (max-width: 640px) {
        margin-top: 4px;
      }
    }

    li:before {
      content: "\2022";
      margin-left: -4px;
      margin-right: 10px;

      @media screen and (max-width: 1024px) {
        margin-left: -15px;
        margin-right: 5px;
      }
    }

    li {
      list-style-type: none;
    }

    > li {
      margin-top: 16px;
    }

    &.awards {
      font-size: 16px;
      line-height: 31px;
    }
  }
  @media screen and (max-width: 640px) {
    margin: 0;
    padding: 24px;
    padding-bottom: 0px;
    padding-top: 16px;
    width: calc(100% - 48px);
    &.biography {
      padding: 0;
    }
  }
}

.scroll-image {
  position: absolute;
  right: 10%;
  top: 85%;
  > img {
    width: 35px;
    height: 73px;
  }
  @media screen and (max-width: 1070px) {
    display: none;
  }
}

.mt-20p {
  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }
}

.mt-24p {
  margin-top: 0px;
  @media screen and (min-width: 641px) {
    margin-top: 24px;
  }
}
