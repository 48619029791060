.general-page {
  .carousel-container {
    margin-bottom: 24px;
  }

  @media screen and (max-width: 639px) {
    .carousel-container {
      padding-left: 24px;
      overflow: visible;
      margin-bottom: 24px;
    }
  }

  .content {
    width: 100%;
    display: flex;
    margin-bottom: 120px;
    margin-top: 22px;
    &.awards-mb-20 {
      margin-bottom: 20px;
    }

    &.books {
      margin-top: 0px;
    }

    .photo {
      width: 53%;
      flex-direction: column;

      &.biography {
        > img {
          border-radius: 25px;
        }
      }

      &.trust-egg {
        width: 92%;
        img {
          color: white;
          flex: 1;
          object-fit: contain;
          max-height: 620px;
          background: none;
          box-shadow: none;
          &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
          }
        }
      }

      img {
        color: white;
        flex: 1;
        width: 100%;
        object-fit: cover;
        background: #111111;
        max-height: 620px;
        box-shadow: 0 21px 4vh -16px rgba(0, 0, 0, 0.25);
        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }
      &.slider-photos {
        padding-bottom: 40px;
        width: 65%;

        img {
          object-fit: contain;
          max-height: 560px;
          height: 475px;
          &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
          }
        }

        iframe {
          color: white;
          flex: 1;
          width: 100%;
          max-height: 560px;
          background: linear-gradient(0deg, #333333, #333333);
          box-shadow: 0 21px 4vh -16px rgba(0, 0, 0, 0.25);
          &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
          }
        }
        @media screen and (max-width: 750px) {
          padding-bottom: 40px;
        }
        @media screen and (max-width: 400px) {
          padding-bottom: 15px;
        }
      }
    }
    .details {
      padding: 10px 60px 10px 10px;
      width: 47%;
      &.justify {
        place-self: center;
      }
      @media screen and (max-width: 1070px) {
        &.trust-details {
          padding: 0;
        }
      }

      @media screen and (max-width: 1240px) {
        width: calc(43% - 80px);
        .container-heading {
          font-size: 36px;
          line-height: 39px;
          &.biography {
            font-size: 75px;
            line-height: 72px;
            &.biography-topic {
              font-size: 22px;
              line-height: 27px;
            }
          }
          &.trust {
            font-size: 75px;
            line-height: 72px;
          }
        }
      }

      @media screen and (max-width: 1070px) {
        .container-heading {
          &.trust {
            font-size: 44px;
            line-height: 42px;
            letter-spacing: -0.005em;
            text-align: center;
          }
          &.biography {
            font-size: 44px;
            line-height: 42px;
            &.biography-topic {
              font-size: 18px;
              line-height: 22px;
            }
          }
        }
      }

      @media screen and (max-width: 640px) {
        .container-heading {
          &.trust {
            font-size: 24px;
            line-height: 22px;
            letter-spacing: -0.005em;
            text-align: center;
          }
          &.biography {
            font-size: 28px;
            line-height: 26px;
            &.biography-topic {
              font-size: 16px;
              line-height: 19px;
            }
          }
        }
      }

      @media screen and (max-width: 500px) {
        .container-heading {
          font-size: 24px;
          line-height: 26px;
        }
        .donate-button {
          width: 327px;
        }
      }

      @media screen and (max-width: 350px) {
        .donate-button {
          width: 90%;
          margin-left: 5%;
        }
      }
    }

    @media screen and (max-width: 1070px) {
      flex-direction: column-reverse;
      margin-bottom: 20px;
      .details {
        margin: 0;
        padding-top: 24px;
        width: unset;
        .container-body {
          margin-bottom: 40px;
        }
      }

      .photo {
        width: 100%;

        &.slider-photos,
        &.trust-egg {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: 640px) {
      .details {
        margin: 0;
        padding: 24px;
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .photo {
        width: calc(100% - 48px);
        padding: 0 24px 24px;

        &.slider-photos,
        &.trust-egg {
          width: calc(100% - 48px);
          padding: 0 24px 24px;
          padding-bottom: 0px;
        }
      }
    }
  }

  .images {
    .image-item {
      position: relative;
      padding: 0 24px 40px;
      margin: auto;
      max-width: 172px;
      user-select: none;
      @media screen and (max-width: 1080px) {
        padding: 0 18px 30px;
        margin-right: 12px;
        max-width: 172px;
      }
      @media screen and (max-width: 960px) {
        padding: 0;
        max-width: unset;
      }
      &__bg {
        background-color: #e5e5e5;
        top: 40px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        max-width: 220px;

        @media screen and (max-width: 960px) {
          display: none;
        }
      }
      .photo {
        position: relative;
        background: linear-gradient(0deg, #333333, #333333);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 88px;
        max-width: 172px;
        width: 100%;
        user-select: none;
        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }
    }
  }
}

.next-button {
  top: 98% !important;
  background-color: white !important;
  width: 46px !important;
  height: 46px !important;
  border-radius: 50%;
  background-image: url("../../images/Right-Arrow.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 20px !important;
  font-size: 0;
  line-height: 0;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.prev-button {
  top: 98% !important;
  background-color: white !important;
  width: 46px !important;
  height: 46px !important;
  background-size: 20px !important;
  border-radius: 50%;
  background-image: url("../../images/Left-Arrow.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  z-index: 2;
  font-size: 0;
  line-height: 0;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.prev-button:before,
.next-button:before {
  content: "" !important;
}

@media screen and (max-width: 750px) {
  .next-button {
    width: 52px !important;
    height: 52px !important;
    top: 96% !important;
  }

  .prev-button {
    width: 52px !important;
    height: 52px !important;
    top: 96% !important;
  }
}

@media screen and (max-width: 400px) {
  .next-button {
    width: 36px !important;
    height: 36px !important;
    top: 95.2% !important;
    background-size: 16px !important;
  }

  .prev-button {
    width: 36px !important;
    height: 36px !important;
    top: 95.2% !important;
    background-size: 16px !important;
  }
}

.image-count {
  display: flex;
  flex-direction: row;
  place-content: space-between;
  place-self: center;
  height: 50px;
  color: #7a2538;
  font-family: OpenSans-Regular;
  font-size: 14px;
  font-weight: 600;

  > p {
    margin-top: -7px;
  }

  @media screen and (max-width: 400px) {
    font-size: 16px;
    margin-top: 18px;
  }
}

.spinner {
  width: 100%;
  text-align: center;
  .lds-dual-ring {
    align-self: center;
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid black;
    border-color: black transparent black transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.slick-next,
.slick-prev,
.slick-arrow {
  display: none !important;
}
