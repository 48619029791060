@import "../../styles/index.scss";

.footer-div {
  height: 240px;
  justify-content: center;
  background-color: #fff9f9;
  .icons-wrapper {
    display: flex;
    flex-direction: row;
    place-content: space-between;

    .icons-container {
      display: flex;
      flex-direction: row;

      > a {
        margin-right: 30px;
      }
    }
  }
  .donate-button {
    width: 250px;
    height: 60px;
    background-color: #efd0d0;
    border-radius: 60px;
    outline: none !important;
    border: none;
    color: #85001e;
    font-size: 16px;
    cursor: pointer;
    font-weight: 700;
    font-family: Rubik-Regular;
    line-height: 25px;
    box-shadow: 0 17px 20px -12px hsla(0, 34.55%, 89.22%, 1);

    &:hover {
      background-color: #efd0d0;
      outline: $color-primary;
      border: 2px solid $color-primary;
      color: #85001e;
      font-size: 16px;
    }

    &:active {
      background-color: #efd0d0 !important;
      outline: none !important;
      border: 2px solid $color-primary !important;
      color: #85001e !important;
      font-size: 16px;
      box-shadow: none !important;
    }

    &:focus {
      background-color: #efd0d0 !important;
      outline: none !important;
      border: 2px solid $color-primary !important;
      color: #85001e !important;
      font-size: 16px;
      box-shadow: none !important;
    }

    @media screen and (max-width: 500px) {
      &.footer {
        width: 327px;
      }
    }

    @media screen and (max-width: 350px) {
      &.footer {
        width: 90%;
        margin-left: 5%;
      }
    }
  }
  //   &.mobile {
  //     padding: 0px 40px;
  //     width: calc(100% - 80px);
  //   }
}

@media screen and (max-width: 768px) {
  .footer-div {
    height: 375px;
    .icons-wrapper {
      align-content: center;
      flex-direction: column;
      .icons-container {
        align-self: center;
        order: 2;
        &.email {
          order: 0;
        }
        .fb-container {
          margin-left: 30px;
        }
      }
    }
    .donate-button {
      align-self: center;
      order: 1;
    }
    .caption {
      align-self: center;
    }
  }
}

@media screen and (max-width: 380px) {
  .footer-div {
    height: 375px;
    .icons-wrapper {
      align-content: center;
      flex-direction: column;
      .icons-container {
        align-self: center;
        order: 2;
        width: 90%;
        justify-content: space-between;
        margin-left: 5%;
        &.email {
          order: 0;
          width: 100%;
          text-align: center;
          margin-left: 0;
          place-content: center;
        }
        .fb-container {
          margin-left: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 868px) {
  .footer-div {
    .caption {
      &.corporate {
        font-size: 12px;
      }
      &.email {
        font-size: 13px;
      }
    }
  }
}
