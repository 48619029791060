.books-parent-div {
  width: 100%;
  text-align: center;
  > p {
    margin-left: 2px;
  }

  .famous-heading {
    font-size: 18px;
    font-family: OpenSans-Regular;
    font-weight: 1000;
    letter-spacing: 1px;
    color: #5b3d43;
    line-height: 28px;

    @media screen and (max-width: 640px) {
      font-size: 12px;
      line-height: 14px;
    }
  }
  @media screen and (max-width: 640px) {
    padding: 24px;
    width: calc(100% - 48px);
  }
}

.poetry {
  display: flex;
  flex-direction: column;
  width: 100%;
  > img {
    width: 42%;
    align-self: center;

    @media screen and (max-width: 1070px) {
      width: 55%;
    }

    @media screen and (max-width: 968px) {
      width: 85%;
    }

    @media screen and (max-width: 640px) {
      width: 100%;
    }
  }
}

.comment {
  width: 100%;
  display: flex;
  background-color: white;

  .single-comment {
    padding: 40px 60px;
    > p {
      align-self: center;
      font-family: OpenSans-Regular;
      font-size: 16px;
      font-weight: 400;
      color: #5a697f;
      line-height: 28px;
      @media screen and (max-width: 640px) {
        font-size: 14px;
        line-height: 26px;
      }
    }
  }
  .avatar {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .commenter-info {
    display: flex;
    flex-direction: row;
    > p {
      align-self: center;
      margin-left: 16px;
      font-family: OpenSans-Regular;
      font-size: 18px;
      font-weight: 600;
      color: black;
      line-height: 30px;

      @media screen and (max-width: 640px) {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
}

.book-subheading {
  font-size: 16px;
  font-family: OpenSans-Regular;
  font-weight: 600;
  color: #85001e;
  margin-bottom: 4px;
  line-height: 19px;
  letter-spacing: 2%;
  margin-left: 2px;

  &.mb-12 {
    margin-bottom: 12px;
  }
}

.book-title {
  font-size: 46px;
  font-family: OpenSans-Regular;
  font-weight: 900;
  letter-spacing: 1px;
  color: #85001e;
  margin-bottom: 6px;
  line-height: 52px;

  @media screen and (max-width: 640px) {
    font-size: 28px !important;
    line-height: 26px !important;
  }

  @media screen and (max-width: 1070px) {
    font-size: 40px;
    line-height: 38px;
  }
}

.divider {
  width: 120px;
  border: 1px solid #0e3437;
}

.each-link {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  width: 500px;

  @media screen and (max-width: 640px) {
    width: 100%;
  }

  li:before {
    content: "\2022";
    margin-left: -4px;
    margin-right: 10px;

    @media screen and (max-width: 1024px) {
      margin-left: -15px;
      margin-right: 5px;
    }
  }

  li {
    list-style-type: none;
  }

  > li {
    font-family: OpenSans-Regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.2px;
    color: #5a697f;
    align-self: center;
    text-align: center;

    @media screen and (max-width: 640px) {
      font-size: 13px !important;
      line-height: 25px !important;
    }

    @media screen and (max-width: 430px) {
      text-align: left;
    }
  }

  > button {
    height: 44px;
    width: 140px;
    border-radius: 40px;
    margin-left: 10px;
    cursor: pointer;
    font-family: Rubik-Regular;
    font-weight: 400;
    font-size: 11px;
    outline: none;
    line-height: 14px;
    color: #7a2538;
    background-color: #f8ecec;
    border: 1px solid #7a2538;
    &:hover {
      border: 2.5px solid #85001e;
    }

    @media screen and (max-width: 640px) {
      height: 44px;
      width: 110px;
      border-radius: 25px;
    }
  }
}

.links-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.margin-section {
  margin-bottom: 30px;
  margin-top: 120px;
  @media screen and (max-width: 640px) {
    margin-top: 60px;
    margin-bottom: 18px;
  }
}

.margin-bottom {
  margin-bottom: 20px;
}

.book-reviews {
  margin-top: 44px;
  .divider {
    margin-top: 44px;
    @media screen and (max-width: 640px) {
      width: 60px;
    }
  }
  .review-image {
    width: 42%;

    @media screen and (max-width: 1070px) {
      width: 65%;
    }

    @media screen and (max-width: 968px) {
      width: 80%;
    }

    @media screen and (max-width: 640px) {
      width: 100%;
    }
  }

  &:first-child {
    margin-top: 0px !important;
  }

  &:last-child {
    .divider {
      display: none;
    }
  }
}

.book-reviews:first-of-type {
  margin-top: 0px;
}
