.icon-small {
  width: 17px;
  height: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.icon-large {
  width: 42px;
  height: 42px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @media screen and (max-width: 960px) {
    width: 32px;
    height: 32px;
  }

  @media screen and (max-width: 639px) {
    width: 24px;
    height: 24px;
  }
}

.social-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(246, 222, 223, 0.4);
  &:hover {
    background-color: #ecd0d2;
  }
}

.social-icon-large {
  @extend .social-icon;
  width: 84px;
  height: 84px;

  @media screen and (max-width: 960px) {
    width: 64px;
    height: 64px;
  }

  @media screen and (max-width: 639px) {
    width: 48px;
    height: 48px;
  }
}

.icon-fb {
  background-image: url("../images/icons/1x/FB.png");
}

.icon-tw {
  background-image: url("../images/icons/1x/Twitter.png");
}

.icon-yt {
  background-image: url("../images/icons/1x/Youtube.png");
}

.icon-ig {
  background-image: url("../images/icons/1x/Instagram.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  /* Retina-specific stuff here */

  .icon-fb {
    background-image: url("../images/icons/2x/FB.png");
  }

  .icon-tw {
    background-image: url("../images/icons/2x/Twitter.png");
  }

  .icon-yt {
    background-image: url("../images/icons/2x/Youtube.png");
  }

  .icon-ig {
    background-image: url("../images/icons/2x/Instagram.png");
  }
}
