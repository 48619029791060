.biography-item {
  width: 100%;
  margin-bottom: 120px;
  @media screen and (max-width: 960px) {
    margin-bottom: 0;
  }
  .content {
    display: flex;
    position: relative;

    .details {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-right: 48px;
    }
    .photo {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 53%;
      z-index: 1;
      img {
        width: 100%;
        color: white;
        flex: 1;
        object-fit: contain;
        max-height: 620px;
        box-shadow: 0 21px 4vh -16px rgba(0, 0, 0, 0.25);
        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }
    }

    @media screen and (max-width: 960px) {
      flex-direction: column-reverse;

      .details {
        padding: 24px;
        margin: 0;
      }
      .photo {
        max-width: unset;
        width: calc(100%);
      }
    }

    @media screen and (max-width: 639px) {
      .details {
        padding: 0 24px 24px;
      }
      .photo {
        padding: 24px;
        max-width: unset;
        min-width: unset;
        width: calc(100% - 48px);
      }
    }
  }

  // &.odd {
  //   .content {
  //     .details {
  //       margin-right: 48px;
  //     }
  //     .border {
  //       right: -120px;
  //     }
  //   }
  // }

  &.even {
    .content {
      flex-direction: row-reverse;
      .details {
        margin-right: 0;
        margin-left: 48px;
      }

      @media screen and (max-width: 1070px) {
        flex-direction: column-reverse;

        .details {
          padding: 24px;
          margin: 0;
        }
        .photo {
          max-width: unset;
          width: calc(100%);
        }
      }

      @media screen and (max-width: 639px) {
        .details {
          padding: 0 24px 24px;
        }
        .photo {
          padding: 24px;
          max-width: unset;
          min-width: unset;
          width: calc(100% - 48px);
        }
      }
    }
  }
}
