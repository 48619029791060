.landing-page {
  background-image: url("../../images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 0 120px;
  font-family: "Lato", sans-serif;
  .logo {
    width: 176px;
    transition: width 0.2s;
  }

  .cover {
    position: absolute;
    bottom: 0;
    right: 0;
    object-fit: contain;
    transition: height 0.2s;

    &.large {
      content: url("../../images/Desktop/banner.png");
    }

    &.medium {
      content: url("../../images/iPad/banner.png");
    }

    &.small {
      content: url("../../images/iPhone/banner.png");
    }
  }

  .content {
    position: relative;
    min-height: 660px;
  }

  .versus {
    max-width: 608px;
    backdrop-filter: blur(4px);
  }

  .signature {
    width: 132px;
  }

  .verse-container {
    justify-content: center;
  }

  .footer {
    width: 250px;
    align-items: center;
    margin-bottom: 129px;
    .icons {
      width: 100%;
    }
  }
  @media screen and (max-width: 960px) {
    .menu-items {
      right: -70px !important;
      width: 100vw !important;
    }
  }

  @media screen and (max-width: 639x) {
    .menu-items {
      right: -40px !important;
    }
  }
}

@media screen and (min-width: 1920px) {
  .landing-page {
    .versus {
      max-width: 960px;
    }
    .signature {
      width: 164px;
    }
  }
}

@media screen and (max-width: 1240px) {
  .landing-page {
    padding: 0 70px;
  }
}

@media screen and (max-width: 1080px) {
  .landing-page {
    .logo {
      width: 159px;
      margin-bottom: 64px;
    }
    .verse-container {
      justify-content: flex-start;
    }
  }
}

@media screen and (max-width: 1024px) {
  .landing-page {
    .content {
      min-height: 540px;
    }
  }
}

@media screen and (max-width: 860px) {
  .landing-page {
    .versus {
      width: 100%;
    }
    .footer {
      margin-bottom: 206px;
    }
  }
}

@media screen and (max-width: 720px) {
  .landing-page {
    .footer {
      margin-bottom: 150px;
      max-width: 250px;
      width: 40%;
      min-width: 120px;
    }
    .social-icon:first-child {
      margin-left: -12px;
    }
  }
}

@media screen and (max-height: 992px) {
  .landing-page {
    .logo {
      margin-bottom: 32px;
      width: 120px;
    }
  }
}

@media screen and (max-width: 639px) {
  .cover {
    width: 100%;
  }
  .landing-page {
    padding: 0 40px;
    .logo {
      width: 96px;
      // margin-bottom: 18px;
      @media screen and (min-height: 720px) {
        margin-bottom: 64px;
      }
    }
    .signature {
      width: 84px;
    }
    .footer {
      .icons {
        display: grid;
        grid-template-columns: auto auto;
        grid-auto-rows: min-content;
        gap: 12px;
        margin-left: -6px;
        width: 76px;
        align-self: flex-start;
        .social-icon:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.landing-page.mobile {
  min-height: unset !important;
  padding: 0 40px;
  width: calc(100% - 80px);
  .logo {
    width: 84px;
    margin-bottom: 0;
  }
  .cover {
    content: url("../../images/iPhone/banner.png");
    bottom: 0;
    right: 0;
    min-height: unset !important;
    max-height: unset !important;
    width: unset;
  }

  .content {
    min-height: unset !important;
  }

  .verse-container {
    justify-content: center;
  }

  .versus {
    width: 380px;

    @media screen and (max-width: 580px) {
      width: 340px;
    }
  }

  .signature {
    width: 46px;
  }

  .footer {
    margin-bottom: 32px;
    width: 250px;
    align-items: flex-start;
    .icons {
      margin-bottom: 8px;
      .social-icon:first-child {
        width: unset;
        margin-right: 12px;
      }
    }
  }
}
