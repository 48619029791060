.title-bar-select {
  display: flex;
  align-items: center;

  &.top-category {
    padding: 24px 0px;
  }

  &.left-category {
    padding-bottom: 24px;
    max-width: 266px;
  }

  &.top-category {
    @media screen and (min-width: 1070px) {
      display: none;
    }
  }

  &.left-category {
    @media screen and (max-width: 1070px) {
      display: none;
    }
  }

  .category-select__control {
    border-radius: 60px !important;
    width: 100%;
    border: 1px solid #7a2538;
    background-color: #f8ecec;
    cursor: pointer;
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;

    .category-select__single-value {
      color: #7a2538;
      font-family: OpenSans-Regular;
      font-size: 14px;
      font-weight: 600;
      line-height: 28px;
    }
  }
  .css-tj5bde-Svg {
    color: #7a2538 !important;
    margin-right: 12px !important;
  }

  .category-select__dropdown-indicator {
    color: #7a2538 !important;
  }

  .category-select__indicator-separator {
    display: none;
  }

  .category-select {
    width: 100%;
  }

  .separator {
    width: 64px;
    height: 1px;
    background-color: #333;
    margin-right: 24px;
  }

  @media screen and (max-width: 960px) {
    h1,
    .separator {
      display: none;
    }
    .category-select {
      width: 100%;
    }

    .category-select__control {
      width: 100%;
    }
  }

  @media screen and (max-width: 640px) {
    &.top-category {
      padding: 24px;
    }
  }
}
